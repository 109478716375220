import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    ReactNode,
} from "react";
import { useFavoriteList } from "@danishagro/b2b/src/hooks/api/useFavoriteList.hook";
import { useAppData } from "./appData.context";

interface FavoriteContextType {
    favorites: Set<string>; // Set of product variant IDs
    loading: boolean;
    refreshFavorites: () => Promise<void>;
    addFavorite: (productId: string, variantId: string) => Promise<void>;
    removeFavorite: (productId: string, variantId: string) => Promise<void>;
}

// Correctly typed context creation
const FavoriteContext = createContext<FavoriteContextType | undefined>(undefined);

// Define the props type for the provider to include children
interface FavoriteProviderProps {
    children: ReactNode;
}

export const FavoriteProvider = ({ children }: FavoriteProviderProps) => {
    const { customerNumber } = useAppData();
    const { fetchFavoriteList, addProductToFavorites, removeProductFromFavorites } =
        useFavoriteList(customerNumber, customerNumber);

    const [favorites, setFavorites] = useState<Set<string>>(new Set());
    const [loading, setLoading] = useState(false);

    const refreshFavorites = useCallback(async () => {
        setLoading(true);
        const favoriteList = await fetchFavoriteList();
        const favoriteSet = new Set(favoriteList.map((item) => item.productVariantId));
        setFavorites(favoriteSet);
        setLoading(false);
    }, [fetchFavoriteList]);

    const addFavorite = useCallback(
        async (productId: string, variantId: string) => {
            await addProductToFavorites({ productId, variantId });
            await refreshFavorites(); // Ensure we await this to update the state correctly
        },
        [addProductToFavorites, refreshFavorites]
    );

    const removeFavorite = useCallback(
        async (productId: string, variantId: string) => {
            await removeProductFromFavorites({ productId, variantId });
            await refreshFavorites(); // Ensure we await this to update the state correctly
        },
        [removeProductFromFavorites, refreshFavorites]
    );

    useEffect(() => {
        refreshFavorites();
    }, [refreshFavorites]);

    return (
        <FavoriteContext.Provider
            value={{ favorites, loading, refreshFavorites, addFavorite, removeFavorite }}
        >
            {children}
        </FavoriteContext.Provider>
    );
};

export const useFavorites = () => {
    const context = useContext(FavoriteContext);
    if (!context) {
        throw new Error("useFavorites must be used within a FavoriteProvider");
    }
    return context;
};
