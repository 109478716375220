import React from "react";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import S from "./ImageLink.module.scss";

export interface DA_ImageLinkProps {
    title: string;
    url: string;
    image: string;
    imageAltText: string;
    limitedHeight?: boolean;
}

export const DA_ImageLink = ({
    title,
    url,
    image,
    imageAltText,
    limitedHeight,
}: DA_ImageLinkProps) => {
    return (
        <DA_BasicLink href={url} title={title} className={S.imageLink}>
            <div className={cn(S.imageWrapper, limitedHeight && S.limitedHeight)}>
                <DA_Image src={image} alt={imageAltText || title} className={S.image} />
            </div>
            <div className={S.bottomInfo}>
                <DA_Text html={title} noPadding />
                <DA_Icon
                    name={DA_IconNames.ChevronRight}
                    useSvgDimensions={true}
                    className={S.arrow}
                />
            </div>
        </DA_BasicLink>
    );
};
