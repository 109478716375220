import React, { MouseEvent, useCallback, useState } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_CartList } from "@organisms/CartList/CartList.component";
import { DA_CartTotal } from "@organisms/CartTotal/CartTotal.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_ReceiptMobileProps } from "./ReceiptMobile.interface";
// Import style
import S from "./ReceiptMobile.module.scss";

export const DA_ReceiptMobile = ({
    shippingDetails,
    customer,
    customerNumber,
    orderLines,
    price,
    hideDriverMessageButton,
    farmInTimeDriverMessage,
    farmInTimeReferenceId,
    invoiceDetails,
}: DA_ReceiptMobileProps) => {
    const { getDictionaryString } = useTranslations();
    const [isMobileBasketCollapsed, setIsMobileBasketCollapsed] = useState<boolean>(true);
    const isMobileBasketCollapsedClass = isMobileBasketCollapsed ? S.isCollapsed : undefined;

    // Expand mobile basket list
    const onMobileBasketExpandToggle = useCallback(
        (event?: MouseEvent<HTMLButtonElement>) => {
            event?.preventDefault();

            setIsMobileBasketCollapsed(!isMobileBasketCollapsed);
        },
        [isMobileBasketCollapsed]
    );

    return (
        <section
            onClick={onMobileBasketExpandToggle}
            onKeyDown={(e) => {
                // Execute on "Enter" key press
                if (e.code === "Enter" || e.code === "Space") {
                    onMobileBasketExpandToggle();
                }
            }}
            role="button" // role indicates it is meant to be a button
            tabIndex={0}
            className={cn(S.wrapper, S.mobileWrapper)}
        >
            <div className={S.header}>
                {/* {getDictionaryString("CheckoutCartListHeading")} */}

                <DA_ButtonLink
                    icon={
                        isMobileBasketCollapsed
                            ? DA_IconNames.ChevronSmallDown
                            : DA_IconNames.ChevronSmallUp
                    }
                    title={
                        isMobileBasketCollapsed
                            ? getDictionaryString("Vis ordredetaljer")
                            : getDictionaryString("Skjul ordredetaljer")
                    }
                />
            </div>
            <div className={cn(S.mobileBasketDetailsInner, isMobileBasketCollapsedClass)}>
                <div className={S.itemWrapper}>
                    <div className={S.infobox}>
                        <DA_Title h3>{getDictionaryString("delivery")}</DA_Title>
                        <div className={S.infoboxInner}>
                            <div>
                                <strong>{getDictionaryString("deliveryAddress")}</strong>
                                {shippingDetails ? (
                                    <div className={S.shippingInfo}>
                                        <span className={S.info}>
                                            {shippingDetails.companyName}
                                        </span>
                                        <span className={S.info}>{shippingDetails.address}</span>
                                        <span className={S.info}>
                                            {shippingDetails.zipCode} {shippingDetails.city}
                                        </span>
                                        {farmInTimeDriverMessage && (
                                            <div className={cn(S.info, S.driverMessage)}>
                                                {getDictionaryString("driverMessageCartTitle")}
                                                {": "}
                                                {farmInTimeDriverMessage}
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={S.itemWrapper}>
                    <div className={S.infobox}>
                        <DA_Title h3>{getDictionaryString("payment")}</DA_Title>
                        <div className={S.infoboxInner}>
                            <div>
                                <strong>{getDictionaryString("billingAddress")}</strong>
                                {invoiceDetails ? (
                                    <div className={S.shippingInfo}>
                                        <span className={S.info}>
                                            {invoiceDetails.invoiceAddress?.companyName}
                                        </span>
                                        <span className={S.info}>
                                            {invoiceDetails.invoiceAddress?.name}
                                        </span>
                                        {/** Show delivery address */}
                                        <span className={S.info}>
                                            {invoiceDetails.invoiceAddress.address}
                                        </span>
                                        <span className={S.info}>
                                            {`${invoiceDetails.invoiceAddress.zipCode} ${invoiceDetails.invoiceAddress.city}`}
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                            <div>
                                <strong>{getDictionaryString("paymentMethod")}</strong>
                                <div>{getDictionaryString("OneStepCheckoutPaymentMethod")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={S.itemWrapper}>
                    {/** Order lines */}
                    <div className={S.orderlinesWrapper}>
                        <div className={S.orderLinesHeader}>
                            <div>
                                {customer?.name ? (
                                    <strong>
                                        {getDictionaryString("account")}: {customer.name} -{" "}
                                        {customerNumber}
                                    </strong>
                                ) : null}

                                <DA_Title h3>{getDictionaryString("orderDetails")}</DA_Title>
                            </div>
                        </div>

                        <DA_CartList
                            className={S.orderlines}
                            items={orderLines}
                            itemPrices={price.lines}
                            hideDeliveryOptions
                            showDeliveryText
                            noShadow
                            readOnly
                            extraPadding
                            showAlternativeCardItem
                            hideDriverMessageButton={hideDriverMessageButton}
                            hideDriverMessage={!!farmInTimeDriverMessage}
                            farmInTimeReferenceId={farmInTimeReferenceId}
                            isReceiptPage
                        />
                    </div>

                    {/** Summary */}
                    <div className={S.summaryWrapper}>
                        <DA_CartTotal className={S.summary} prices={price} hideButtons />
                    </div>
                </div>
            </div>
        </section>
    );
};
