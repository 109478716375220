import React from "react";
import { DA_Tooltip } from "@danishagro/shared/src/components/atoms/Tooltip/Tooltip.component";
import { DA_Product } from "@danishagro/shared/src/interfaces/product.interface";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import Skeleton from "react-loading-skeleton";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import S from "./ProductListPrice.module.scss";

export interface DA_ProductListPriceProps {
    price: DA_Product["price"];
    discountedPrice?: number;
    hasVariants?: boolean;
    onClick?: () => void;
    onlyPrice?: boolean;
}

export const DA_ProductListPrice = ({
    price,
    discountedPrice,
    hasVariants,
    onClick,
    onlyPrice,
}: DA_ProductListPriceProps) => {
    const { currentCulture } = useAppData();
    const { getDictionaryString } = useTranslations();
    const showQuantityDiscount = false;

    return (
        <div
            className={S.priceWrapper}
            itemProp="offers"
            itemScope
            itemType="https://schema.org/Offer"
        >
            <div className={S.price}>
                <div className={cn(S.currentPrice, onlyPrice && S.onlyPrice)}>
                    {hasVariants && (
                        <span>
                            {getDictionaryString("from", {
                                uppercaseFirst: true,
                            })}{" "}
                        </span>
                    )}

                    {price ? (
                        <>
                            {formatNumber(price.priceWithoutVat, currentCulture, {
                                decimals: 2,
                            })}
                            <meta itemProp="price" content={price.priceWithoutVat.toString()} />
                            <meta itemProp="priceCurrency" content={price.currency} />
                        </>
                    ) : (
                        <Skeleton width={70} />
                    )}
                </div>

                {discountedPrice && (
                    <div className={S.discountedPrice}>
                        {formatNumber(discountedPrice, currentCulture, {
                            decimals: 2,
                        })}
                    </div>
                )}
            </div>

            {!onlyPrice && (
                <div className={S.pushContent}>
                    <DA_ButtonLink
                        title={getDictionaryString("freight is added")}
                        className={S.shippingLink}
                        onClick={onClick}
                    />

                    {showQuantityDiscount && (
                        <span className={S.quantityDiscount}>
                            {getDictionaryString("quantity discount")}{" "}
                            <DA_Tooltip text="Mængderabat tooltip" icon={DA_IconNames.Info} />
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};
