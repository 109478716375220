import { FilterBuilder, StringCollectionDataValue } from "@relewise/client";

const ASSORTMENT_NAME = "webUserGroups";

export function addWebUserGroupsFilter(
    f: FilterBuilder,
    customerNumber: string,
    cvrAndCustomerNumbers
) {
    if (cvrAndCustomerNumbers?.accounts?.length) {
        if (customerNumber) {
            const accounts = cvrAndCustomerNumbers.accounts;
            let customerGroups = [];
            for (const account of accounts) {
                const customer = account.customers.find((c) => c.number === customerNumber);
                if (customer) {
                    customerGroups = customer.customerGroups || [];
                    break;
                }
            }
            const groupNames = customerGroups.map((group) => group.name);

            const groups = new StringCollectionDataValue(groupNames);

            f.addProductDataFilter(
                ASSORTMENT_NAME,
                (cb) => {
                    cb.addContainsCondition(groups, "Any");
                },
                true, // mustMatchAllConditions
                true, // filterOutIfKeyIsNotFound
                false // negated
            );
        }
    }
}
