import { useState, useCallback } from "react";
import { useRequest } from "@danishagro/shared/src/hooks/useRequest.hook";

// Rename the hook-specific type to avoid collision
interface FavoriteProductBasic {
    productId: string;
    variantId: string;
}

interface VersionedRequestParams {
    version?: string;
}

interface AddToFavoriteParams extends VersionedRequestParams {
    productId: string;
    variantId: string;
}

interface RemoveFromFavoriteParams extends VersionedRequestParams {
    productId: string;
    variantId: string;
}

// API response structure that matches what the API sends
interface FavoriteProductResponse {
    productId: string;
    productNumber: string;
    productVariantId: string;
    productName: string;
    productUrl: string;
    quantity: number;
    priceMultipler: number;
    allowExpressDelivery: boolean;
    allowCropProtectionDelivery: boolean;
    image: {
        url: string;
        height: number;
        width: number;
    };
    weight: number;
}

export const useFavoriteList = (customerNumber: string, customerId: string) => {
    const request = useRequest();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // Function to build the URL with optional version
    const buildUrl = useCallback(
        (version?: string) => {
            let url = `/farmintime/customers/${customerNumber}/favoritelistproducts?customerId=${customerId}`;
            if (version) {
                url += `&version=${version}`;
            }
            return url;
        },
        [customerNumber, customerId]
    );

    // Fetch favorite list from server with explicit version
    const fetchFavoriteList = useCallback(
        async ({ version }: VersionedRequestParams = {}): Promise<FavoriteProductResponse[]> => {
            setLoading(true);
            setError(null);
            try {
                const url = buildUrl(version);

                const response = await request.get(url); // Get the response from the API

                // Assuming response is the object that contains favoriteList directly
                const favoriteList = response.favoriteList; // Access the favoriteList directly

                if (!favoriteList) {
                    throw new Error("No favoriteList in response");
                }

                return favoriteList; // Return the favoriteList (array)
            } catch (error) {
                console.error("Failed to fetch favorite list:", error);
                setError("Failed to fetch favorite list");
                return [];
            } finally {
                setLoading(false);
            }
        },
        [buildUrl, request]
    );

    // Add product to favorite list with explicit version
    const addProductToFavorites = useCallback(
        async ({ productId, variantId, version }: AddToFavoriteParams) => {
            setLoading(true);
            setError(null);
            try {
                const body = { productId, variantId: variantId || "" };
                const url = buildUrl(version);
                await request.post(url, body);
            } catch (error) {
                setError("Failed to add product to favorites");
            } finally {
                setLoading(false);
            }
        },
        [buildUrl, request]
    );

    // Remove product from favorite list with explicit version
    const removeProductFromFavorites = useCallback(
        async ({ productId, variantId, version }: RemoveFromFavoriteParams) => {
            setLoading(true);
            setError(null);
            try {
                const body = { productId, variantId: variantId || "" };
                const url = buildUrl(version);
                await request.delete(url, body);
            } catch (error) {
                setError("Failed to remove product from favorites");
            } finally {
                setLoading(false);
            }
        },
        [buildUrl, request]
    );

    return {
        fetchFavoriteList,
        addProductToFavorites,
        removeProductFromFavorites,
        loading,
        error,
    };
};
