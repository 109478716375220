import React from "react";
import { Helmet } from "react-helmet";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Favorites } from "@templates/Favorites/Favorites.component";
import { DA_Breadcrumb } from "@danishagro/shared/src/components/molecules/Breadcrumb/Breadcrumb.component";
import { usePage } from "@danishagro/shared/src/contexts/page.context";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";

export const DA_FavoritesPage = () => {
    const { globalTranslations, getDictionaryString } = useTranslations();
    const { currentPathDetails } = usePage();

    return (
        <DA_Container>
            <Helmet>
                <title>
                    {getDictionaryString("favorites")}
                    {" | "}
                    {globalTranslations.pageTitlePostfix}
                </title>
            </Helmet>

            <DA_Breadcrumb
                lastIsLink
                breadcrumbItems={[
                    {
                        title: getDictionaryString("webshop"),
                        href: "/",
                        id: globalThis.frontPageId,
                    },
                    ...currentPathDetails.map(({ name, url, path, categoryId }) => ({
                        title: name,
                        href: url || path,
                        id: categoryId.toString(),
                    })),
                ]}
            />

            <DA_Favorites />
        </DA_Container>
    );
};
