import React, { useEffect, useState } from "react";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { useFavoriteList } from "@hooks/api/useFavoriteList.hook";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useProductPrices } from "@danishagro/shared/src/hooks/useProductPrices.hook";
import Skeleton from "react-loading-skeleton";
import { DA_ProductListPrice } from "@molecules/ProductListPrice/ProductListPrice.component";
import {
    DA_EmptyState,
    DA_EmptyStateTypes,
} from "@danishagro/shared/src/components/molecules/EmptyState/EmptyState.component";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { B2bImageSrc } from "@templates/Relewise/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { DA_ContentBox } from "@danishagro/shared/src/components/atoms/ContentBox/ContentBox.component";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import toast from "react-hot-toast";
import { DA_Toast } from "@danishagro/shared/src/components/molecules/Toast/Toast.component";
import S from "./Favorites.module.scss";

// Interface for the product returned from the API
interface FavoriteProductResponse {
    productId: string;
    productNumber: string;
    productVariantId: string;
    productName: string;
    productUrl: string;
    quantity: number;
    priceMultipler: number;
    allowExpressDelivery: boolean;
    allowCropProtectionDelivery: boolean;
    image: {
        url: string;
        height: number;
        width: number;
    };
    weight: number;
}

export const DA_Favorites = () => {
    const { customerNumber, showPrices } = useAppData();
    const { getDictionaryString } = useTranslations();
    const {
        fetchFavoriteList,
        loading: favoritesLoading,
        removeProductFromFavorites,
    } = useFavoriteList(customerNumber, customerNumber);
    const [favorites, setFavorites] = useState<FavoriteProductResponse[]>([]); // Store favorites in state

    const { addToCart } = useCart();

    // Use hook to fetch prices
    const priceData = useProductPrices(favorites);

    // Dimensions for thumb images
    const IMAGE_WIDTH = 67;
    const IMAGE_HEIGHT = 85;

    // Fetch the favorites when the component mounts
    useEffect(() => {
        const loadFavorites = async () => {
            const result: FavoriteProductResponse[] = await fetchFavoriteList(); // Expecting an array directly
            setFavorites(result); // Set the array directly
        };
        loadFavorites();
    }, [fetchFavoriteList]);

    const isLoading = favoritesLoading || !priceData;

    const handleRemoveFavorite = async (
        productId: string,
        variantId: string,
        displayName: string
    ) => {
        try {
            // Remove the product from favorites
            await removeProductFromFavorites({ productId, variantId });

            // Refetch the favorites list
            const updatedFavorites = await fetchFavoriteList();
            setFavorites(updatedFavorites);
            toast.custom(() => (
                <DA_Toast
                    altStyle={true}
                    message={
                        <>
                            <div>{getDictionaryString("ToastRemovedFromFavoritesText")}</div>
                            <div className="break-all">{displayName}</div>
                        </>
                    }
                />
            ));
        } catch (error) {
            console.error("Failed to remove favorite:", error);
        }
    };

    const handleAddToCart = (productVariantId: string) => {
        addToCart({
            productId: productVariantId,
            quantity: 1,
        });
    };

    return (
        <div className={S.wrapper}>
            <div className={S.header}>
                <DA_Title h1 noMargin>
                    {getDictionaryString("Favorites")}
                </DA_Title>
            </div>
            <DA_ContentBox>
                <div className={S.favoritesList}>
                    {isLoading ? (
                        <DA_EmptyState
                            type={DA_EmptyStateTypes.Loading}
                            title={getDictionaryString("loading favorites")}
                            iconOnly
                        />
                    ) : favorites.length > 0 ? (
                        <>
                            {/* Header section before mapping the items */}
                            <div className={S.favoritesHeader}>
                                {/* <h2>{getDictionaryString("Your Favorite Items")}</h2>
                                <p>{getDictionaryString("Manage your favorite products here.")}</p> */}
                                <div className={S.headerMain}>
                                    <div className={S.headerDiv1} />
                                    <div className={S.headerDiv2}>
                                        <span className={S.headerTitle}>
                                            {getDictionaryString("name")}
                                        </span>
                                    </div>
                                </div>
                                <div className={S.headerDiv3}>
                                    <span className={S.headerTitle}>
                                        {getDictionaryString("price")}
                                    </span>
                                </div>
                                <div className={S.headerDiv4} />
                            </div>

                            {/* Mapping favorite items */}
                            {favorites.map((item, index) => (
                                <div key={item.productId} className={S.favoriteItem}>
                                    <DA_BasicLink href={item.productUrl} className={S.imageLink}>
                                        <div className={S.favoriteImage}>
                                            <DA_Image
                                                src={B2bImageSrc(
                                                    item.image.url,
                                                    ImageConfig.PdpThumbnail
                                                )}
                                                alt={item.productName}
                                                width={IMAGE_WIDTH}
                                                height={IMAGE_HEIGHT}
                                            />
                                        </div>
                                        <div className={S.favoriteDetails}>
                                            <span className={S.productId}>
                                                {item.productNumber}
                                            </span>
                                            <span className={S.productName}>
                                                {item.productName}
                                            </span>
                                        </div>
                                    </DA_BasicLink>
                                    <div className={S.favoritePrice}>
                                        {showPrices &&
                                        priceData[index]?.productNumber === item.productNumber ? (
                                            <DA_ProductListPrice
                                                price={priceData[index]}
                                                onlyPrice
                                            />
                                        ) : (
                                            <Skeleton width={70} /> // Show skeleton while price loads
                                        )}
                                    </div>
                                    <div className={S.favoriteActions}>
                                        <DA_Button
                                            icon={DA_IconNames.Cart}
                                            onClick={() => handleAddToCart(item.productVariantId)}
                                        />

                                        <DA_Button
                                            icon={DA_IconNames.Trash}
                                            isGhost
                                            onClick={() =>
                                                handleRemoveFavorite(
                                                    item.productId,
                                                    item.productVariantId,
                                                    item.productName
                                                )
                                            }
                                            className={S.remove}
                                        />
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <DA_EmptyState
                            type={DA_EmptyStateTypes.NoFavorites}
                            title={getDictionaryString("FavoriteListNoItems")}
                            iconOnly
                        />
                    )}
                </div>
            </DA_ContentBox>
        </div>
    );
};
