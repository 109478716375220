import React from "react";
import { DA_ContentBox } from "@danishagro/shared/src/components/atoms/ContentBox/ContentBox.component";
import { DA_SwitchElement } from "@danishagro/shared/src/components/atoms/SwitchElement/SwitchElement.component";
import Skeleton from "react-loading-skeleton";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import S from "./CollectedDelivery.module.scss";

interface DA_CollectedDeliveryProps {
    combinedDeliveryAvailableCount: number;
    isLoading?: boolean;
}

export const DA_CollectedDelivery = ({
    combinedDeliveryAvailableCount,
    isLoading,
}: DA_CollectedDeliveryProps) => {
    const { combinedDelivery, setCombinedDeliveryMode } = useCart();
    const { getDictionaryString } = useTranslations();

    const handleSwitchChange = async (checked: boolean) => {
        try {
            await setCombinedDeliveryMode(checked);
        } catch (error) {
            console.error("Error setting combined delivery mode:", error);
        }
    };

    return (
        <div className={S.collectedDelivery}>
            <DA_ContentBox smallVerticalPadding smallMargin>
                <div className={S.container}>
                    {isLoading ? (
                        // Show skeletons for loading state
                        <div className={S.collectedDeliverySkeleton}>
                            <Skeleton height={20} width={200} />
                            <div className={S.collectedDeliverySkeletonRight}>
                                {" "}
                                <Skeleton height={20} width={80} />
                            </div>
                        </div>
                    ) : (
                        <div className={S.collectedDeliveryItem}>
                            <div className={S.collectedDeliveryItemText}>
                                <DA_Icon
                                    name={DA_IconNames.Van}
                                    className={S.collectedDeliveryItemIcon}
                                />
                                <span className={S.collectedDeliveryItemTextMarked}>
                                    {getDictionaryString("collectedDelivery.collectedDelivery")}
                                </span>
                                <span className={S.collectedDeliveryItemTextInfo}>
                                    {`${getDictionaryString(
                                        "collectedDelivery.isAvailableFor"
                                    )} ${combinedDeliveryAvailableCount} ${getDictionaryString(
                                        "collectedDelivery.ofYours"
                                    )} ${
                                        combinedDeliveryAvailableCount > 1
                                            ? getDictionaryString("collectedDelivery.products")
                                            : getDictionaryString("collectedDelivery.product")
                                    } ${getDictionaryString("collectedDelivery.ifYouCanWait")}`}
                                </span>
                            </div>
                            <div className={S.collectedDeliveryItemSwitch}>
                                <span className={S.collectedDeliveryItemSwitchText}>
                                    {getDictionaryString("collectedDelivery.yesThankYou")}
                                </span>
                                <DA_SwitchElement
                                    id="combined-delivery-switch"
                                    checked={combinedDelivery}
                                    onChange={handleSwitchChange}
                                    label="Enable combined delivery"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </DA_ContentBox>
        </div>
    );
};
