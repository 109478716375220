import React from "react";
import { DA_Accordion } from "@danishagro/shared/src/components/molecules/Accordion/Accordion.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_CartList } from "@organisms/CartList/CartList.component";
import { DA_CartTotal } from "@organisms/CartTotal/CartTotal.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { DA_ReceiptDesktopProps } from "./ReceiptDesktop.interface";
// Import style
import S from "./ReceiptDesktop.module.scss";

export const DA_ReceiptDesktop = ({
    priceFormatted,
    shippingDetails,
    customer,
    customerNumber,
    orderLines,
    price,
    hideDriverMessageButton,
    farmInTimeDriverMessage,
    farmInTimeReferenceId,
    invoiceDetails,
}: DA_ReceiptDesktopProps) => {
    const { getDictionaryString } = useTranslations();
    const { showPrices } = useAppData();
    const tagLine = showPrices ? `${getDictionaryString("total")}: ${priceFormatted}` : "";

    return (
        <DA_Accordion
            className={S.accordion}
            title={getDictionaryString("orderDetails")}
            tagline={tagLine}
            showToggleIcons
            showMediumHeader
        >
            <div className={S.split}>
                {/** Shipping */}
                <div className={S.infobox}>
                    <DA_Title h3>{getDictionaryString("delivery")}</DA_Title>
                    <div className={S.infoboxInner}>
                        <div>
                            <strong>{getDictionaryString("deliveryAddress")}</strong>
                            {shippingDetails ? (
                                <>
                                    <div className={S.shippingInfo}>
                                        <span className={S.info}>
                                            {shippingDetails.companyName}
                                        </span>
                                        <span className={S.info}>{shippingDetails.address}</span>
                                        <span className={S.info}>
                                            {shippingDetails.zipCode} {shippingDetails.city}
                                        </span>
                                    </div>
                                    {farmInTimeDriverMessage && (
                                        <div className={cn(S.info, S.driverMessage)}>
                                            {getDictionaryString("driverMessageCartTitle")}
                                            {": "}
                                            {farmInTimeDriverMessage}
                                        </div>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>

                {/** Payment */}
                <div className={S.infobox}>
                    <DA_Title h3>{getDictionaryString("payment")}</DA_Title>
                    <div className={S.infoboxInner}>
                        <div>
                            <strong>{getDictionaryString("billingAddress")}</strong>
                            {invoiceDetails ? (
                                <div className={S.shippingInfo}>
                                    <span className={S.info}>
                                        {invoiceDetails?.invoiceAddress?.companyName}
                                    </span>
                                    <span className={S.info}>
                                        {invoiceDetails?.invoiceAddress?.name}
                                    </span>
                                    {/** Show delivery address */}
                                    <span className={S.info}>
                                        {invoiceDetails?.invoiceAddress?.address}
                                    </span>
                                    <span className={S.info}>
                                        {`${invoiceDetails.invoiceAddress.zipCode} ${invoiceDetails.invoiceAddress.city}`}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                        <div>
                            <strong>{getDictionaryString("paymentMethod")}</strong>
                            <div>{getDictionaryString("OneStepCheckoutPaymentMethod")}</div>
                        </div>
                    </div>
                </div>
            </div>

            {/** Order lines */}
            <div className={S.orderlinesWrapper}>
                <div className={S.orderLinesHeader}>
                    <div>
                        {customer?.name ? (
                            <strong>
                                {getDictionaryString("account")}: {customer.name} - {customerNumber}
                            </strong>
                        ) : null}

                        <DA_Title h3>{getDictionaryString("orderDetails")}</DA_Title>
                    </div>
                </div>

                <DA_CartList
                    className={S.orderlines}
                    items={orderLines}
                    itemPrices={price?.lines}
                    hideDeliveryOptions
                    showDeliveryText
                    noShadow
                    readOnly
                    extraPadding
                    hideDriverMessageButton={hideDriverMessageButton}
                    hideDriverMessage={!!farmInTimeDriverMessage}
                    farmInTimeReferenceId={farmInTimeReferenceId}
                    isReceiptPage
                />
            </div>

            {/** Summary */}
            <div className={S.summaryWrapper}>
                <DA_CartTotal className={S.summary} prices={price} hideButtons />
            </div>
        </DA_Accordion>
    );
};
