import React, {
    FormEvent,
    MouseEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { toast } from "react-hot-toast";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_RadioButton } from "@danishagro/shared/src/components/atoms/RadioButton/RadioButton.component";
import { DA_CartTotal } from "@organisms/CartTotal/CartTotal.component";
import { DA_Checkbox } from "@danishagro/shared/src/components/atoms/Checkbox/Checkbox.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_InlineLink } from "@danishagro/shared/src/components/atoms/InlineLink/InlineLink.component";
import { DA_Input } from "@danishagro/shared/src/components/atoms/Input/Input.component";
import { DA_Spinner } from "@danishagro/shared/src/components/atoms/Spinner/Spinner.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useNavigate } from "react-router-dom";
import { useCustomer } from "@danishagro/shared/src/hooks/useCustomer.hook";
import { DA_Alert } from "@danishagro/shared/src/components/atoms/Alert/Alert.component";
import { useAddressesApi } from "@danishagro/shared/src/hooks/api/useAddressesApi.hook";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import { useAddressFromSessionStorage } from "@danishagro/shared/src/hooks/useAddressFromSessionStorage.hook";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { DA_UserAccountDetails } from "@danishagro/shared/src/interfaces/userAccountDetails.interface";
import { useUserInformationApi } from "@danishagro/shared/src/hooks/api/useUserInformationApi.hook";
import { useProducts } from "@danishagro/shared/src/gtm/useProducts";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useDebounce } from "react-use";
import { getApiUrl } from "@danishagro/shared/src/helpers/getApiUrl.helper";
import { B2B_API_ROOT } from "@danishagro/shared/src/constants/urlRoot.constants";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { DA_ViewStates } from "@danishagro/shared/src/content/viewState/viewStates";
import { DA_TextArea } from "@danishagro/shared/src/components/atoms/TextArea/TextArea.component";
import { DA_DatePicker } from "@danishagro/shared/src/components/molecules/DatePicker/DatePicker.component";
import { DA_CheckoutCartList } from "./components/CheckoutCartList/CheckoutCartList.component";
import { useDeliveryAddresses } from "./hooks/useDeliveryAddresses.hook";
import { useDeliveryOptions } from "./hooks/useDeliveryOptions.hook";
import S from "./OneStepCheckout.module.scss";

export const DA_OneStepCheckout = () => {
    const navigate = useNavigate();
    const { getDictionaryString } = useTranslations();
    const { scrollTo } = useScreen();
    const {
        fetchWithAuthB2b,
        siteVariant,
        deliveryOptionDeliveryModeCode,
        isCheckoutDeliveryEnabled,
        isCheckoutDeliveryDatepickerEnabled,
        isCheckoutAdditionalInformationEnabled,
        isCheckoutAdditionalInformationPhoneNumberEnabled,
        isFarmInTimeEnabled,
    } = useAppData();

    // Addresses and user-info
    const { getDeliveryAddresses } = useAddressesApi();
    const { getUserAccountDetails } = useUserInformationApi();
    const {
        deliveryOptions,
        selectedDeliveryOption,
        setSelectedDeliveryOption,
        deliveryDate,
        setDeliveryDate,
        deliveryOptionMinDate,
    } = useDeliveryOptions();

    //Extended info
    const [reference, setReference] = useState<string>(undefined);
    const [phoneNumber, setPhoneNumber] = useState<string>(undefined);
    const [isDeliveryAddressEnabled, setIsDeliveryAddressEnabled] = useState(true);

    // Customer
    const { customer, customerNumber } = useCustomer();

    // Cart
    const {
        cartId,
        orderSecret,
        orderLines,
        priceData,
        priceStatus,
        shippingDetails,
        // latestShippingDate,
        completeOrder,
        completeOrderSimple2,
        completeWithPriceService,
        resetCart,
        isFarmInTimeCart,
    } = useCart();

    const [viewState, setViewState] = useState<DA_ViewStates>(DA_ViewStates.NONE);
    const [errors, setErrors] = useState<string[]>([]);
    const [hasAddress, setHasAddress] = useState(false);
    const [allAddresses, setAllAddresses] = useState<DA_Address[]>([]);
    const [firstAddressInList, setFirstAddressInList] = useState<DA_Address>();
    const [userAccountDetails, setUserAccountDetails] = useState<DA_UserAccountDetails>();
    const { hasAddressInSessionStorage, getAddressObject } = useAddressFromSessionStorage();
    const { isMobile } = useScreen();

    // // GTM Tracking Checkout
    // TODO: Wait for priceService, and add price

    const transformedOrderLines = useMemo(() => {
        return orderLines.map((orderLine) => {
            // Extract price for each line
            const priceInfo = priceData?.lines?.find((line) => line?.orderLineId === orderLine?.id);

            // Extract the category from the URL
            const category = orderLine?.url?.split("/").slice(0, -1).join("_").replace(/^_/, "");

            return {
                productName: orderLine.productName,
                productNumber: orderLine.productNumber,
                price: priceInfo ? priceInfo?.unitPrice?.priceWithoutVat : 0,
                currency: "DKK",
                quantity: orderLine.quantity,
                category,
                // ... other properties
            };
        });
    }, [orderLines, priceData]);

    const trackProducts = useProducts(transformedOrderLines);

    useEffect(() => {
        setIsDeliveryAddressEnabled(selectedDeliveryOption === deliveryOptionDeliveryModeCode);
    }, [selectedDeliveryOption, deliveryOptionDeliveryModeCode]);

    useEffect(() => {
        // Trigger GTM tracking only if priceData is available and has lines
        if (priceData && priceData.lines && priceData.lines.length > 0) {
            trackProducts("begin_checkout");
        }
    }, [trackProducts, priceData]);

    useEffect(() => {
        if (shippingDetails?.address) {
            setHasAddress(true);
        }
    }, [shippingDetails?.address]);

    useEffect(() => {
        scrollTo(0);
    }, [scrollTo]);

    // Get all customer addresses and set the first one in state hasAddress
    useEffect(() => {
        getDeliveryAddresses().then((response) => {
            setFirstAddressInList(response[0]);
            setAllAddresses(response);
            setHasAddress(true);
        });
    }, [getDeliveryAddresses]);

    useEffect(() => {
        getUserAccountDetails(customerNumber).then((response) => {
            setUserAccountDetails(response);
        });
    }, [customerNumber, getUserAccountDetails]);

    useEffect(() => {
        getAddressObject(allAddresses);
    }, [allAddresses, getAddressObject]);

    // Delivery Addresses
    const { onEditDeliveryAddressClicked, deliveryStatusModalViewState } =
        useDeliveryAddresses(setAllAddresses);

    // TODO: Clear errors on any input change

    const calculateTotalValue = (orderLines) => {
        return orderLines.reduce((acc, item) => acc + item.price * item.quantity, 0);
    };

    const onFormSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event?.preventDefault();

            const form = new FormData(event.target as HTMLFormElement);
            const tmpErrors = [];

            // Validation
            if (form.get("eula") !== "true") {
                tmpErrors.push("EULA");
            }

            if (!orderLines?.[0]) {
                tmpErrors.push("ORDERLINES");
            }

            if (isCheckoutDeliveryEnabled) {
                if (isCheckoutDeliveryDatepickerEnabled && !deliveryDate) {
                    tmpErrors.push("DELIVERYDATE");
                }
            }

            if (isCheckoutAdditionalInformationEnabled) {
                if (isCheckoutAdditionalInformationPhoneNumberEnabled && !phoneNumber) {
                    tmpErrors.push("PHONE");
                }
            }

            if (tmpErrors.length > 0) {
                setErrors(tmpErrors);
                return;
            }

            setViewState(DA_ViewStates.LOADING);

            const handleOrderCompletion = (orderId: string) => {
                // GTM Purchase Tracking Logic
                const totalValue = calculateTotalValue(transformedOrderLines);
                const transactionId = `T_${orderId}`; // Use the orderId for transaction ID

                // Track the purchase event
                trackProducts("purchase", {
                    transaction_id: transactionId,
                    value: totalValue,
                    currency: "DKK",
                    tax: 0, // If applicable, add tax calculation here
                    shipping: 0, // If applicable, add shipping calculation here
                });

                navigate(`/receipt/${orderId}`);
                resetCart();
            };

            // Submit the order based on the delivery option
            if (isCheckoutDeliveryEnabled && isCheckoutAdditionalInformationEnabled) {
                if (siteVariant.alias === "vilofarm") {
                    completeWithPriceService({
                        id: cartId,
                        deliveryModeCode: selectedDeliveryOption,
                        reference,
                    })
                        .then(handleOrderCompletion)
                        .catch(() => {
                            setViewState(DA_ViewStates.NONE);
                            toast.error(getDictionaryString("orderCompleteError"));
                        });
                } else {
                    completeOrderSimple2({
                        id: cartId,
                        deliveryModeCode: selectedDeliveryOption,
                        deliveryDate: isCheckoutDeliveryDatepickerEnabled ? deliveryDate : null,
                        reference,
                        phoneNumber: isCheckoutAdditionalInformationPhoneNumberEnabled
                            ? phoneNumber
                            : null,
                    })
                        .then(handleOrderCompletion)
                        .catch(() => {
                            setViewState(DA_ViewStates.NONE);
                            toast.error(getDictionaryString("orderCompleteError"));
                        });
                }
            } else {
                completeOrder()
                    .then(handleOrderCompletion)
                    .catch(() => {
                        setViewState(DA_ViewStates.NONE);
                        toast.error(getDictionaryString("orderCompleteError"));
                    });
            }
        },
        [
            orderLines,
            siteVariant,
            isCheckoutDeliveryEnabled,
            isCheckoutDeliveryDatepickerEnabled,
            isCheckoutAdditionalInformationEnabled,
            isCheckoutAdditionalInformationPhoneNumberEnabled,
            deliveryDate,
            phoneNumber,
            completeOrderSimple2,
            completeWithPriceService,
            cartId,
            selectedDeliveryOption,
            reference,
            navigate,
            resetCart,
            trackProducts,
            transformedOrderLines,
            getDictionaryString,
            completeOrder,
        ]
    );

    const currentAddress = getAddressObject(allAddresses);

    const [isAccountDetailsCollapsed, setIsAccountDetailsCollapsed] = useState<boolean>(true);
    const isAccountDetailsCollapsedClass = isAccountDetailsCollapsed ? S.isCollapsed : undefined;

    const { getFarmInTimeDriverMessageByAddress } = useFarmInTimeApi();

    const onAccountDetailsToggleClicked = useCallback(
        (event?: MouseEvent<HTMLButtonElement>) => {
            event?.preventDefault();

            setIsAccountDetailsCollapsed(!isAccountDetailsCollapsed);
        },
        [isAccountDetailsCollapsed]
    );

    // Driver Message Start

    const [driverMessage, setDriverMessage] = useState<string>();
    const driverMessageRef = useRef<string>();

    const getDriverMessage = async () => {
        const response = await getFarmInTimeDriverMessageByAddress(
            customerNumber,
            currentAddress?.id
        );

        driverMessageRef.current = response?.message;
        setDriverMessage(() => {
            // If a message is already set, don't overwrite it
            // If no message is set, set the message from the API
            return response?.message || "";
        });
    };

    const updateDriverMessageForAllProducts = async (
        customerNumber: string,
        driverMessage: string
    ) => {
        try {
            const url = getApiUrl(B2B_API_ROOT, "/checkout/updatedrivermessageforallproducts");

            const response = await fetchWithAuthB2b(url, customerNumber, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    orderSecret: orderSecret,
                    customerNumber: customerNumber,
                    driverMessage: driverMessage,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to update driver message");
            }

            return await response;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (currentAddress && isFarmInTimeCart) {
            getDriverMessage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAddress]);

    useDebounce(
        async () => {
            // TODO: Disable driverMessage for all

            if (
                isFarmInTimeEnabled &&
                isFarmInTimeCart &&
                typeof driverMessage !== "undefined" &&
                driverMessageRef.current !== driverMessage
            ) {
                await updateDriverMessageForAllProducts(customerNumber, driverMessage);
            }
        },
        1000,
        [driverMessage]
    );

    // Driver Message End

    return (
        <DA_Container className={S.wrapper} noTopPadding>
            <div className={S.header}>
                <DA_Title h1 noMargin>
                    {getDictionaryString("OneStepCheckoutHeader")}
                </DA_Title>

                {!isFarmInTimeCart ? (
                    <DA_InlineLink
                        href="/cart"
                        title={getDictionaryString("OneStepCheckoutBackToCart")}
                    />
                ) : null}
            </div>

            <div className={S.inner}>
                <form className={S.content} onSubmit={onFormSubmit}>
                    {/** Account Details */}
                    <div className={S.section}>
                        <div className={S.toolbar}>
                            <DA_Title h3 noMargin>
                                {getDictionaryString("OneStepCheckoutAccountDetails")}
                            </DA_Title>
                            <DA_ButtonLink
                                icon={
                                    isAccountDetailsCollapsed
                                        ? DA_IconNames.ChevronSmallDown
                                        : DA_IconNames.ChevronSmallUp
                                }
                                title={
                                    isAccountDetailsCollapsed
                                        ? getDictionaryString("OneStepCheckoutShowMore")
                                        : getDictionaryString("OneStepCheckoutShowLess")
                                }
                                onClick={onAccountDetailsToggleClicked}
                            />
                        </div>

                        {/** Account */}
                        <DA_Input
                            id="account"
                            readOnly
                            className={S.accountWrapper}
                            disabled
                            defaultValue={
                                customer
                                    ? `${customer?.name} - Konto: ${customerNumber}`
                                    : undefined
                            }
                        />

                        <div className={cn(S.accountDetailsInner, isAccountDetailsCollapsedClass)}>
                            <div className={S.split}>
                                {/** Company */}
                                <DA_Input
                                    id="company"
                                    readOnly
                                    disabled
                                    label={getDictionaryString("OneStepCheckoutCompany")}
                                    defaultValue={userAccountDetails?.companyName}
                                />

                                {/** Company Number */}
                                <DA_Input
                                    id="companyNumber"
                                    readOnly
                                    disabled
                                    label={getDictionaryString("OneStepCheckoutCompanyNumber")}
                                    defaultValue={userAccountDetails?.cvr}
                                />
                            </div>

                            <div className={S.split}>
                                {/** Name */}
                                <DA_Input
                                    id="name"
                                    readOnly
                                    disabled
                                    label={getDictionaryString("OneStepCheckoutName")}
                                    defaultValue={userAccountDetails?.name}
                                />

                                {/** Phone */}
                                <DA_Input
                                    id="phone"
                                    readOnly
                                    disabled
                                    label={getDictionaryString("OneStepCheckoutPhone")}
                                    defaultValue={userAccountDetails?.phone}
                                />
                            </div>

                            {/** E-mail */}
                            <DA_Input
                                id="email"
                                readOnly
                                disabled
                                label={getDictionaryString("OneStepCheckoutEmail")}
                                defaultValue={userAccountDetails?.email}
                                helperText={getDictionaryString("OneStepCheckoutEmailLabel")}
                            />
                        </div>
                    </div>

                    {/** Delivery Options */}
                    {isCheckoutDeliveryEnabled && (
                        <div className={S.section}>
                            <DA_Title h3>{getDictionaryString("deliveryOptions")}</DA_Title>
                            <div className={cn(S.toolbar, S.toolbarDelivery)}>
                                <ul className={S.deliveryOptionsWrapper}>
                                    {deliveryOptions.map(({ title, description, code }, index) => (
                                        <li key={`${code}-${index}`}>
                                            <label
                                                htmlFor={`${code}-${index}`}
                                                className={cn(
                                                    S.deliveryOption,
                                                    selectedDeliveryOption === code && S.selected
                                                )}
                                            >
                                                <DA_RadioButton
                                                    id={`${code}-${index}`}
                                                    name="deliveryMethod"
                                                    label={title}
                                                    labelElementClassName={S.labelTitle}
                                                    checked={selectedDeliveryOption === code}
                                                    onChange={() => setSelectedDeliveryOption(code)}
                                                    value={code}
                                                    vertical
                                                    disabled={false}
                                                />
                                                <div className={S.labelDescription} />
                                                {description}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                                {isCheckoutDeliveryDatepickerEnabled && (
                                    <div className={S.deliveryDateWrapper}>
                                        <div className={S.labelTitle}>
                                            {getDictionaryString(
                                                "deliveryoptions.deliverydatetitle"
                                            )}
                                        </div>
                                        <DA_DatePicker
                                            minDate={deliveryOptionMinDate}
                                            date={deliveryDate}
                                            onChange={(newDate) => setDeliveryDate(newDate)}
                                            invalid={errors && errors.includes("DELIVERYDATE")}
                                        />
                                        {errors && errors.includes("DELIVERYDATE") ? (
                                            <div className={S.error}>
                                                {getDictionaryString(
                                                    "deliveryoptions.deliverydatemessage"
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {/** Delivery Address */}
                    <div
                        className={cn(
                            S.section,
                            S.sectionDeliveryAddress,
                            !isDeliveryAddressEnabled && S.sectionDisabled
                        )}
                    >
                        <div className={S.toolbar}>
                            <DA_Title h3 noMargin>
                                {getDictionaryString("deliveryAddress")}
                            </DA_Title>

                            <div className={S.toolbarButtonWrapper}>
                                {deliveryStatusModalViewState === DA_ViewStates.LOADING ? (
                                    <DA_Spinner />
                                ) : null}

                                <DA_ButtonLink
                                    title={
                                        hasAddress
                                            ? getDictionaryString(
                                                  "OneStepCheckoutEditDeliveryAddress"
                                              )
                                            : getDictionaryString(
                                                  "OneStepCheckoutAddDeliveryAddress"
                                              )
                                    }
                                    onClick={onEditDeliveryAddressClicked}
                                />
                            </div>
                        </div>
                        {hasAddress && (
                            <div className={S.noteWrapper}>
                                <strong className={S.noteHeading}>
                                    <input
                                        type="hidden"
                                        name="shippingDetails_address"
                                        value={
                                            hasAddressInSessionStorage
                                                ? `${currentAddress?.streetName} ${currentAddress?.streetNumber}`
                                                : shippingDetails &&
                                                  shippingDetails?.address?.trim()
                                                ? shippingDetails.address
                                                : `${firstAddressInList?.streetName} ${firstAddressInList?.streetNumber}`
                                        }
                                    />
                                    <input
                                        type="hidden"
                                        name="shippingDetails_zipCode"
                                        value={
                                            hasAddressInSessionStorage
                                                ? `${currentAddress?.zipCode}`
                                                : shippingDetails?.zipCode
                                                ? shippingDetails.zipCode
                                                : `${firstAddressInList?.zipCode}`
                                        }
                                    />
                                    <input
                                        type="hidden"
                                        name="shippingDetails_city"
                                        value={
                                            hasAddressInSessionStorage
                                                ? `${currentAddress?.city}`
                                                : shippingDetails?.city
                                                ? shippingDetails.city
                                                : `${firstAddressInList?.city}`
                                        }
                                    />

                                    {hasAddressInSessionStorage
                                        ? `${currentAddress?.streetName} ${currentAddress?.streetNumber},
                                         ${currentAddress?.zipCode} ${currentAddress?.city}`
                                        : /** Trim method is used because address prop from testdata is being returned with only whitespace */
                                        shippingDetails && shippingDetails.address?.trim()
                                        ? `${shippingDetails.address}, ${shippingDetails.zipCode} ${shippingDetails.city}`
                                        : `${firstAddressInList?.streetName} ${firstAddressInList?.streetNumber},
                                         ${firstAddressInList?.zipCode} ${firstAddressInList?.city}`}
                                </strong>
                            </div>
                        )}
                    </div>

                    {/** Extended info */}
                    {isCheckoutAdditionalInformationEnabled && (
                        <div className={S.section}>
                            <DA_Title h3>{getDictionaryString("extendedInfo")}</DA_Title>
                            <div className={S.extendedInfoWrapper}>
                                <div>
                                    <DA_Input
                                        label={getDictionaryString("reference")}
                                        tooltip={getDictionaryString("referenceInfo")}
                                        onChange={(newValue) => setReference(newValue)}
                                        defaultValue={reference}
                                    />
                                </div>
                                {isCheckoutAdditionalInformationPhoneNumberEnabled && (
                                    <div>
                                        <DA_Input
                                            label={getDictionaryString("endUserPhoneNumber")}
                                            tooltip={getDictionaryString("endUserPhoneNumberInfo")}
                                            onChange={(newValue) => setPhoneNumber(newValue)}
                                            defaultValue={phoneNumber}
                                            invalid={errors && errors.includes("PHONE")}
                                        />
                                        {errors && errors.includes("PHONE") ? (
                                            <div className={S.error}>
                                                {getDictionaryString("endUserPhoneNumberMessage")}
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {/** Shipping Date */}
                    {/*<div className={S.section}>*/}
                    {/*    <div className={S.toolbar}>*/}
                    {/*        <DA_Title h3 noMargin>*/}
                    {/*            {getDictionaryString("OneStepCheckoutShippingHeading")}*/}
                    {/*        </DA_Title>*/}
                    {/*    </div>*/}

                    {/*    <div className={S.noteWrapper}>*/}
                    {/*        <strong className={S.noteHeading}>*/}
                    {/*            {getDictionaryString("OneStepCheckoutShippingDateHeading")}{" "}*/}
                    {/*            {latestShippingDate ? (*/}
                    {/*                format(latestShippingDate, "dd.MM.yyyy")*/}
                    {/*            ) : (*/}
                    {/*                <Skeleton*/}
                    {/*                    width={75}*/}
                    {/*                    baseColor="#ddd"*/}
                    {/*                    highlightColor="#e7e7e7"*/}
                    {/*                />*/}
                    {/*            )}*/}
                    {/*        </strong>*/}
                    {/*        <p className={S.noteText}>*/}
                    {/*            {getDictionaryString("OneStepCheckoutShippingDateText")}*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/** Comment */}
                    {isFarmInTimeCart ? (
                        <div className={S.section}>
                            <div className={S.toolbar}>
                                <DA_Title h3 noMargin>
                                    {getDictionaryString("OneStepCheckoutDriverMessage")}
                                </DA_Title>
                            </div>

                            <DA_TextArea
                                autoComplete="off"
                                inputClassName={S.driverMessage}
                                value={driverMessage}
                                onChange={(value) => setDriverMessage(value)}
                            />
                        </div>
                    ) : null}

                    {/** Submit */}
                    <div className={S.section}>
                        <div className={S.toolbar}>
                            <DA_Title h3 noMargin>
                                {getDictionaryString("OneStepCheckoutApproveOrder")}
                            </DA_Title>
                        </div>

                        <DA_Checkbox
                            name="eula"
                            value="true"
                            required
                            label={
                                <>
                                    <strong>
                                        {getDictionaryString("OneStepCheckoutEulaAccept")}
                                    </strong>{" "}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: getDictionaryString(
                                                "OneStepCheckoutEulaTermsConditions"
                                            ),
                                        }}
                                    />
                                </>
                            }
                        />

                        {/** Validation: EULA */}
                        {errors && errors.includes("EULA") ? (
                            <div className={S.error}>
                                {getDictionaryString("OneStepCheckoutEulaTermsConditionsError")}
                            </div>
                        ) : null}

                        <div className={S.submitButtonWrapper}>
                            {viewState === DA_ViewStates.LOADING ? <DA_Spinner /> : null}
                            <DA_Button
                                disabled={viewState === DA_ViewStates.LOADING}
                                title={getDictionaryString("OneStepCheckoutSubmitOrder")}
                                submit
                            />
                        </div>
                    </div>
                </form>
                <aside className={S.aside}>
                    <DA_CheckoutCartList isFarmInTimeCart={isFarmInTimeCart} items={orderLines}>
                        {/** Validation: OrderLines */}
                        {errors && errors.includes("ORDERLINES") ? (
                            <div className={S.error}>
                                {getDictionaryString("OneStepCheckoutOrderlinesError")}
                            </div>
                        ) : null}
                    </DA_CheckoutCartList>

                    {priceStatus === "FAILED" && (
                        <DA_Alert
                            content={getDictionaryString("Cart prices failed")}
                            type="error"
                            hideCloseBtn
                        />
                    )}

                    {!isMobile && <DA_CartTotal prices={priceData} hideButtons={true} />}
                </aside>
            </div>
        </DA_Container>
    );
};
