// GTM Tracking
import { useCallback } from "react";

// Define the structure of the product data for tracking
type TrackMultiProductsProps = {
    productName: string;
    productNumber: string;
    price?: number | string;
    currency?: string;
    category?: string;
    brand?: string;
    listId?: string;
    listName?: string;
    quantity?: number;
    discount?: number;
};

type PurchaseDataProps = {
    transaction_id: string;
    value: number | string;
    currency: string;
    tax: number;
    shipping: number;
};

interface EcommerceData {
    transaction_id?: string;
    value?: number;
    currency?: string;
    tax?: number;
    shipping?: number;
    items: Array<{
        item_id: string;
        item_name: string;
        price: number;
        quantity: number;
        currency: string;
        discount: number;
        index: number;
        item_brand?: string | null;
        item_category?: string | null;
        item_list_id?: string;
        item_list_name?: string;
    }>;
}

export const useProducts = (products: TrackMultiProductsProps[]) => {
    // Format the price to ensure it has two decimal places
    const formatPrice = useCallback((price: number | string | undefined | null): number => {
        if (price === undefined || price === null) {
            return 0;
        }

        let validPrice: number;

        if (typeof price === "number") {
            validPrice = price;
        } else if (typeof price === "string") {
            // Handle European-formatted strings
            const normalizedPrice = price.replace(/\./g, "").replace(",", ".");
            const parsedPrice = parseFloat(normalizedPrice);
            validPrice = isNaN(parsedPrice) ? 0 : parsedPrice;
        } else {
            validPrice = 0;
        }

        return parseFloat(validPrice.toFixed(2)); // Ensure two decimal places
    }, []);

    const trackProducts = useCallback(
        (eventName: string, additionalData?: PurchaseDataProps) => {
            window.dataLayer = window.dataLayer || [];
            // Clear the previous ecommerce object
            window.dataLayer.push({ ecommerce: null });

            // Map the products to the required format
            const items = products.map((product, index) => ({
                item_id: product.productNumber,
                item_name: product.productName,
                price: formatPrice(product.price),
                quantity: product.quantity || 1,
                discount: product.discount || 0.0,
                currency: product.currency || "DKK",
                item_category: product.category || "",
                item_brand: product.brand || null,
                item_list_id: product.listId || "",
                item_list_name: product.listName || "",
                index: index,
            }));

            let ecommerceData: EcommerceData = {
                items: items,
            };

            // If the event is "purchase", include the additional purchase-related fields
            if (eventName === "purchase" && additionalData) {
                ecommerceData = {
                    ...ecommerceData,
                    transaction_id: additionalData.transaction_id,
                    value: formatPrice(additionalData.value),
                    tax: additionalData.tax,
                    shipping: additionalData.shipping,
                    currency: additionalData.currency,
                };

                window.dataLayer.push({
                    event: "purchase",
                    ecommerce: ecommerceData,
                });
            } else {
                window.dataLayer.push({
                    event: eventName,
                    ecommerce: ecommerceData,
                });
            }
        },
        [products, formatPrice]
    );

    return trackProducts;
};
