import React, { useCallback, useEffect, useMemo } from "react";
import { animated, Transition } from "react-spring";
import { _CartItem, _CartItemProps } from "@organisms/CartList/Item/CartItem.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useProducts } from "@danishagro/shared/src/gtm/useProducts";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { DA_Checkbox } from "@danishagro/shared/src/components/atoms/Checkbox/Checkbox.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import {
    DA_Button,
    DA_ButtonColor,
} from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import {
    DA_DynamicOverlayWrapper,
    useDynamicOverlay,
} from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_DeleteItemsOverlay } from "./Overlay/DeleteItemsOverlay.component";
import { DA_CartListProps } from "./CartList.props";
import { useMarkAll } from "./hooks/useMarkAll.hook";
import S from "./CartList.module.scss";

export const DA_CartList = ({ ...props }: DA_CartListProps) => {
    const { getDictionaryString } = useTranslations();
    const { isMobileOrTablet } = useScreen();
    const {
        markAllItems,
        deletedItems,
        cancelSelection,
        deleteItem,
        showCheckboxes,
        setShowCheckboxes,
        confirmDeleteItems,
    } = useMarkAll();

    // Check if any product is selected for deletion
    const hasCheckedProduct = deletedItems.length > 0;

    // State to keep track of deleted item IDs
    const { showModal, closeModal } = useModal();

    const { priceData } = useCart();
    const { showPrices } = useAppData();
    const { closeOverlay, showOverlay } = useDynamicOverlay();

    const texts = useMemo(
        () => ({
            name: getDictionaryString("name", { uppercaseFirst: true }),
            date: getDictionaryString("latest delivery date", {
                uppercaseFirst: true,
            }),
            amount: getDictionaryString("amount", { uppercaseFirst: true }),
            comment: getDictionaryString("comment", { uppercaseFirst: true }),
            price: getDictionaryString("price", { uppercaseFirst: true }),
        }),
        [getDictionaryString]
    );

    // GTM Tracking
    // TODO: Wait for priceService, and add price
    const transformedItems = useMemo(() => {
        return props.items.map((item) => {
            // Extract price for each line
            const priceInfo = priceData?.lines.find((line) => line?.orderLineId === item?.id);

            // Extract the category from the URL
            const category = item?.url?.split("/").slice(0, -1).join("_").replace(/^_/, "");

            return {
                productName: item.productName,
                productNumber: item.productNumber,
                price: priceInfo ? priceInfo.unitPrice.priceWithoutVat : 0,
                currency: "DKK",
                quantity: item.quantity,
                category,
                // ... other properties
            };
        });
    }, [props.items, priceData]);

    const trackProducts = useProducts(transformedItems);

    useEffect(() => {
        // Trigger GTM tracking only if priceData is available and has lines
        if (priceData && priceData.lines && priceData.lines.length > 0) {
            trackProducts("view_cart");
        }
    }, [trackProducts, priceData, props.itemPrices]);

    const onQuickSelectChanged = () => {
        setShowCheckboxes(!showCheckboxes);
    };

    const activateMarkAllBottomSheet = () => {
        showOverlay(
            {
                id: "activateMarkAll",
                title: getDictionaryString("activateMarkAll"),
                closeButtonToBack: true,
                content: (
                    <DA_DynamicOverlayWrapper
                        content={
                            <DA_ButtonLink
                                onClick={() => {
                                    closeOverlay();
                                    onQuickSelectChanged();
                                }}
                                className={S.quickSelectLink}
                                title={getDictionaryString("MarkAllAndRemove")}
                            />
                        }
                        footer={null}
                    />
                ),
            },
            {
                bottomSheet: {
                    snapPoints: [50],
                },
            }
        );
    };

    const deleteItemsBottomSheet = useCallback(() => {
        showOverlay(
            {
                id: "deleteItemsOverlay",
                title: getDictionaryString("deleteItemsOverlayTitle"),
                closeButtonToBack: true,
                content: (
                    <DA_DynamicOverlayWrapper
                        content={
                            <DA_DeleteItemsOverlay
                                onClick={() => {
                                    confirmDeleteItems(deletedItems.map((i) => i.id));
                                    closeOverlay();
                                }}
                                onClose={closeOverlay}
                            />
                        }
                        footer={null}
                    />
                ),
            },
            {
                bottomSheet: {
                    snapPoints: ({ minHeight }) => [minHeight],
                },
            }
        );
    }, [closeOverlay, confirmDeleteItems, deletedItems, getDictionaryString, showOverlay]);

    const deleteItemsModal = useCallback(() => {
        showModal(
            <DA_DeleteItemsOverlay
                onClick={() => confirmDeleteItems(deletedItems.map((i) => i.id))}
                onClose={closeModal}
            />,
            {
                size: ModalSize.XS,
            }
        );
    }, [closeModal, confirmDeleteItems, deletedItems, showModal]);

    const MarkAllControlComponent: React.FC<{
        items: _CartItemProps[];
        disableControls?: boolean;
    }> = ({ items, disableControls }) => {
        return !disableControls ? (
            <>
                {isMobileOrTablet && (
                    <DA_BasicLink
                        className={S.buttonCancel}
                        href="#"
                        onClick={() => cancelSelection()}
                    >
                        {getDictionaryString("quickSelectCancel")}
                    </DA_BasicLink>
                )}
                <DA_Button
                    onClick={() => markAllItems(items)}
                    title={getDictionaryString("MarkAllButton")}
                    isGhost
                />
                <DA_Button
                    onClick={() => {
                        isMobileOrTablet ? deleteItemsBottomSheet() : deleteItemsModal();
                    }}
                    title={getDictionaryString(
                        isMobileOrTablet ? "MarkAndRemoveButton" : "MarkAndRemoveButtonDesktop"
                    )}
                    icon={DA_IconNames.Trash}
                    disabled={deletedItems.length === 0}
                    iconRight
                    color={DA_ButtonColor.Alert}
                />
            </>
        ) : null;
    };

    return (
        <>
            {showCheckboxes && isMobileOrTablet && (
                <div className={cn(S.controlsWrapper, S.mobile)}>
                    <MarkAllControlComponent
                        items={props.items}
                        disableControls={props.isReceiptPage}
                    />
                </div>
            )}
            {!showCheckboxes && isMobileOrTablet && (
                <button
                    className={cn(
                        S.quickSelectTogggleButton,
                        isMobileOrTablet && S.mobilePositionButton
                    )}
                    onClick={() => activateMarkAllBottomSheet()}
                >
                    <DA_Icon className={S.quickSelectTogggleIcon} name={DA_IconNames.Ellipsis} />
                </button>
            )}

            <section className={cn(S.cartList, props.noShadow || S.withShadow, props.className)}>
                <div
                    className={cn(
                        S.quickSelectWrapper,
                        isMobileOrTablet && S.mobileOrTablet,
                        showCheckboxes && isMobileOrTablet && S.mobilePosition
                    )}
                >
                    {!isMobileOrTablet ? (
                        <div className={S.controlsWrapper}>
                            <MarkAllControlComponent
                                items={props.items}
                                disableControls={props.isReceiptPage}
                            />
                        </div>
                    ) : null}
                </div>
                {/** Header */}
                {!props.readOnly ? (
                    <div className={S.header} aria-hidden={true}>
                        <div className={S.headerItem}>{texts.name}</div>
                        <div className={S.headerItem}>{showPrices && texts.date}</div>
                        <div className={S.headerItem}>{texts.amount}</div>
                        <div className={S.headerItem}>{texts.comment}</div>
                        <div className={S.headerItem}>{showPrices && texts.price}</div>
                    </div>
                ) : null}
                <Transition
                    items={props.items}
                    from={{ opacity: 1 }}
                    leave={{ opacity: 0 }}
                    keys={(item) => item.id}
                >
                    {(style, item) => (
                        <animated.div className={S.item} style={style}>
                            {!props.isReceiptPage && !isMobileOrTablet ? (
                                <DA_Checkbox
                                    label=""
                                    checked={deletedItems.some((i) => i.id === item.id)}
                                    className={S.checkItem}
                                    onChange={() => deleteItem(item)}
                                    redBackground
                                />
                            ) : null}
                            <_CartItem
                                key={item.id}
                                {...item}
                                linePrice={
                                    props.itemPrices?.find(
                                        ({ orderLineId }) => orderLineId === item.id
                                    ) || undefined
                                }
                                hideDeliveryOptions={props.hideDeliveryOptions}
                                hideDriverMessage={props.hideDriverMessage}
                                hideDriverMessageButton={props.hideDriverMessageButton}
                                farmInTimeReferenceId={props.farmInTimeReferenceId}
                                readOnly={props.readOnly}
                                isReceiptPage={props.isReceiptPage}
                                showCheckboxes={showCheckboxes}
                                checked={deletedItems.some((i) => i.id === item.id)}
                                onCheckboxChange={() => deleteItem(item)}
                                disableDeleteButtons={hasCheckedProduct}
                            />
                        </animated.div>
                    )}
                </Transition>
            </section>
        </>
    );
};
