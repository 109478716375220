import React from "react";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Container } from "../../atoms/Container/Container.component";
import { cn } from "../../../helpers/classNames.helper";
import { DA_ToastProps } from "./Toast.props";
import S from "./Toast.module.scss";

export const DA_Toast = (props: DA_ToastProps) => {
    const content = (
        <>
            <div className={S.toastInfo}>{props.message}</div>
            {props.goTo ? (
                <div className={S.toastBasket}>
                    <div className={S.linkToBasket}>{props.goTo}</div>
                </div>
            ) : null}
        </>
    );

    const wrapperClassNames = cn(
        S.toastWrapper,
        props.altStyle && S.altStyle,
        props.type === "error" ? S.error : undefined
    );

    return (
        <DA_Container flexColumn className={S.container}>
            {props.href ? (
                <DA_BasicLink
                    className={wrapperClassNames}
                    href={props.href}
                    target="_self"
                    conventionalRouting
                >
                    {content}
                </DA_BasicLink>
            ) : (
                <div className={wrapperClassNames}>{content}</div>
            )}
        </DA_Container>
    );
};
