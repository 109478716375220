/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
    useCallback,
    useId,
    useState,
    MouseEvent,
    forwardRef,
    useImperativeHandle,
    useRef,
    useEffect,
} from "react";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_ButtonLink } from "../../atoms/ButtonLink/ButtonLink.component";
import { useTranslations } from "../../../contexts/translations/translations.context";
import { AccordionProps, AccordionViewState } from "./Accordion.props";
import S from "./Accordion.module.scss";

interface DA_ProductDescriptionRefInterface {
    showFullDescription: () => void;
    scrollIntoView: () => void;
}

export const DA_Accordion = forwardRef(
    (
        { isOpen = false, ...props }: AccordionProps,
        ref: React.Ref<DA_ProductDescriptionRefInterface>
    ) => {
        const { getDictionaryString } = useTranslations();

        // Initialize viewState based on defaultOpen prop
        const initialState =
            props.defaultOpen || isOpen
                ? AccordionViewState.Expanded
                : AccordionViewState.Collapsed;
        const [viewState, setViewState] = useState<string>(initialState);

        const someDomElementRef = useRef<HTMLDivElement>(null);
        const showFullDescription = () => setViewState(AccordionViewState.Expanded);

        const contentRef = useRef(null);
        const [maxHeight, setMaxHeight] = useState("0px");

        useEffect(() => {
            if (
                props.animation &&
                viewState === AccordionViewState.Expanded &&
                contentRef.current
            ) {
                setMaxHeight(`${contentRef.current.scrollHeight}px`);
            } else {
                setMaxHeight("0px");
            }
        }, [viewState, props.animation]);

        const buttonId = useId();
        const regionId = useId();

        const onToggleButtonClicked = useCallback(
            (event: MouseEvent<HTMLElement>) => {
                event?.stopPropagation();

                if (viewState === AccordionViewState.Collapsed) {
                    setViewState(AccordionViewState.Expanded);
                } else {
                    setViewState(AccordionViewState.Collapsed);
                }

                // Call the onToggle prop if it exists
                if (props.onToggle) {
                    props.onToggle();
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [viewState, props.onToggle]
        );

        // Allow parent components to imperatively open the accordion
        useImperativeHandle(
            ref,
            () => ({
                showFullDescription,
                scrollIntoView: () =>
                    someDomElementRef.current?.scrollIntoView({ behavior: "smooth" }),
            }),
            []
        );


        return (
            <>
                <div
                    ref={someDomElementRef}
                    className={cn(
                        S.wrapper,
                        props.showLargeHeader && S.isLarge,
                        props.showMediumHeader && S.isMedium,
                        props.showSmallHeader && S.isSmall,
                        props.salesTabStatisticStyle && S.salesTabStatisticStyle,
                        props.className,
                        props.allowThickBorder &&
                        viewState === AccordionViewState.Expanded &&
                        S.allowThickBorder
                    )}
                >
                    <div
                        className={cn(
                            S.header,
                            props.headerFullWidth && S.headerFullWidth,
                            props.noPaddingHeader && S.noPaddingHeader
                        )}
                        onClick={!props.toggleOnWrapper ? onToggleButtonClicked : undefined}
                        id={buttonId}
                        role="button"
                        tabIndex={0}
                        aria-controls={regionId}
                        aria-expanded={viewState === AccordionViewState.Expanded}
                    >
                        <div className={cn(S.title, props.headerFullWidth && S.headerFullWidth)}>
                            {props.title}
                        </div>

                        <div className={S.toolbar}>
                            <div className={S.tagline}>{props.tagline}</div>

                            {viewState === AccordionViewState.Expanded ? (
                                <div
                                    className={cn(
                                        S.toggleWrapper,
                                        props.showToggleIcons && S.showMoreLess
                                    )}
                                    onClick={
                                        props.toggleOnWrapper ? onToggleButtonClicked : undefined
                                    }
                                >
                                    {props.showToggleIcons ? (
                                        <DA_Icon
                                            className={cn(
                                                S.toggleIcon,
                                                props?.showLargeArrow && S.showLargeArrow
                                            )}
                                            name={DA_IconNames.ChevronSmallUp}
                                        />
                                    ) : null}

                                    {props.showToggleLabel ? (
                                        <DA_ButtonLink
                                            onClick={onToggleButtonClicked}
                                            title={getDictionaryString("showLess")}
                                        />
                                    ) : null}
                                </div>
                            ) : null}

                            {viewState === AccordionViewState.Collapsed && (
                                <div
                                    className={cn(
                                        S.toggleWrapper,
                                        props.showToggleIcons && S.showMoreLess
                                    )}
                                    onClick={
                                        props.toggleOnWrapper ? onToggleButtonClicked : undefined
                                    }
                                >
                                    {props.showToggleIcons && (
                                        <DA_Icon
                                            className={cn(
                                                S.toggleIcon,
                                                props?.showLargeArrow && S.showLargeArrow
                                            )}
                                            name={DA_IconNames.ChevronSmallDown}
                                        />
                                    )}
                                    {props.showToggleLabel && (
                                        <DA_ButtonLink
                                            onClick={onToggleButtonClicked}
                                            title={getDictionaryString("showMore")}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    {props.animation ? (
                        <div
                            ref={contentRef}
                            id={regionId}
                            role="region"
                            aria-labelledby={buttonId}
                            style={{ maxHeight }}
                            className={cn(
                                S.animationContent,
                                viewState === AccordionViewState.Expanded && S.show
                            )}
                        >
                            {props.children}
                        </div>
                    ) : (
                        viewState === AccordionViewState.Expanded && (
                            <div
                                id={regionId}
                                role="region"
                                aria-labelledby={buttonId}
                                className={S.content}
                            >
                                {props.children}
                            </div>
                        )
                    )}
                </div>
            </>
        );
    }
);

DA_Accordion.displayName = "DA_Accordion";
