import React, {
    FormEvent,
    useCallback,
    useDeferredValue,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useLocation } from "react-router-dom";
import ReactFocusLock from "react-focus-lock";
import { ProductSearchBuilder, ProductSearchResponse } from "@relewise/client";
import { useDebounce } from "react-use";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { useOnClickOutside } from "@danishagro/shared/src/hooks/useOnClickOutside.hook";
import { blurActiveElement } from "@danishagro/shared/src/helpers/blurActiveElement.helper";
import { useBottomSheet } from "@danishagro/shared/src/contexts/bottomSheet/bottomSheet.context";
import { useSiteHeader } from "@danishagro/shared/src/contexts/siteHeader.context";
import { DA_Spinner } from "@danishagro/shared/src/components/atoms/Spinner/Spinner.component";
import { useRelewise } from "@danishagro/shared/src/contexts/relewise.context";
import { useSearch } from "@danishagro/shared/src/hooks/useSearch.hook";
import { addWebUserGroupsFilter } from "@danishagro/shared/src/helpers/relewiseFilters.helper";
import { DA_SearchResults } from "../../../../interfaces/search.interface";
import { _SearchProduct } from "./SearchProduct/SearchProduct.component";
import { RelewiseSearchProduct } from "./interfaces/relewiseSearchProduct.interface";
import { _SearchPage } from "./SearchPage/SearchPage.component";
import S from "./SearchInput.module.scss";

interface DA_SearchInputProps {
    trapFocus?: boolean;
}

export const DA_SearchInput = (props: DA_SearchInputProps) => {
    const [searchTerm, setSearchTerm] = useState("");
    const deferredSearchTerm = useDeferredValue(searchTerm);
    const [viewState, setViewState] = useState<string>("NONE");
    const [results, setResults] = useState<DA_SearchResults>();
    const [relewiseResults, setRelewiseResults] = useState<RelewiseSearchProduct[]>();
    const [totalResults, setTotalResults] = useState(0);
    const { currentSite, cvrAndCustomerNumbers, customerNumber } = useAppData();
    const { getDictionaryString } = useTranslations();
    const location = useLocation();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const controllerRef = useRef<AbortController | null>();
    const search = useSearch();

    const { dismissBottomSheet } = useBottomSheet();
    const { togglePopUp } = useSiteHeader();

    const { getSearcher, relewiseUser } = useRelewise();

    const settings = {
        language: "da",
        currency: "DKK",
        displayedAtLocation: "Relewise Demo Store",
        user: relewiseUser(),
    };

    const builder = new ProductSearchBuilder(settings)
        .setSelectedProductProperties({
            displayName: true,
            pricing: true,
            allData: true,
            dataKeys: ["*"],
        })
        .setExplodedVariants(1)
        .setTerm(searchTerm);
    if (globalThis.useCustomerGroups) {
        builder.filters((f) => {
            addWebUserGroupsFilter(f, customerNumber, cvrAndCustomerNumbers);
        });
    }

    const clearSearch = useCallback(() => {
        setSearchTerm("");
        setResults(undefined);
        setTotalResults(0);
        setViewState("NONE");
    }, []);

    const onResultClicked = useCallback(() => {
        clearSearch();

        dismissBottomSheet();
        togglePopUp(undefined);
    }, [clearSearch, dismissBottomSheet, togglePopUp]);

    const reset = useCallback(() => {
        clearSearch();
        inputRef.current.value = "";
    }, [clearSearch]);

    const onFocus = useCallback(() => {
        if (inputRef.current?.value) {
            setSearchTerm(inputRef.current.value);
        }
    }, []);

    const onKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === "Escape") {
                reset();
            }
        },
        [reset]
    );

    const onSubmit = useCallback((event: FormEvent<HTMLFormElement>) => event.preventDefault(), []);

    useOnClickOutside(
        wrapperRef,
        useCallback(() => {
            clearSearch();
            setTimeout(() => blurActiveElement(), 10);
        }, [clearSearch]),
        [totalResults > 0]
    );

    useDebounce(
        () => {
            if (controllerRef.current) {
                // If a request has already been sent to the API,
                // abort it before sending a new one
                controllerRef.current.abort();
            }

            if (deferredSearchTerm) {
                setViewState("LOADING");
                controllerRef.current = new AbortController();

                if (currentSite === "CMS") {
                    search(
                        deferredSearchTerm,
                        { maxProducts: 10 },
                        { signal: controllerRef.current.signal }
                    )
                        .then((results) => {
                            setResults(results);
                            setViewState("DONE");
                        })
                        .catch((err) => {
                            // Ignore abort errors, since they only occur
                            // when we initiate them ourselves (a few lines up)
                            if (err?.name !== "AbortError") {
                                setViewState("ERROR");
                                console.log(err);
                            }
                        });
                } else {
                    getSearcher()
                        .searchProducts(builder.build())
                        .then((response: ProductSearchResponse) => {
                            if (response && response.results.length > 0) {
                                setViewState("DONE");
                                setRelewiseResults(response.results as RelewiseSearchProduct[]);
                                setTotalResults(response.hits);
                            } else {
                                setViewState("EMPTY");
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                            // Ignore abort errors, since they only occur
                            // when we initiate them ourselves (a few lines up)
                            if (err?.name !== "AbortError") {
                                setViewState("ERROR");
                                console.log(err);
                            }
                        });
                }
            } else {
                setViewState("NONE");
                setResults(undefined);
                setTotalResults(0);
            }
        },
        250,
        [deferredSearchTerm]
    );

    // Reset search on location change
    useEffect(() => {
        reset();
    }, [location, reset]);

    const searchPlaceholderText = useMemo(() => {
        switch (currentSite) {
            case "CMS":
                return getDictionaryString("General.SearchPlaceholderCms");
            case "MYFARM":
                return getDictionaryString("searchPlaceholderMyfarm");
            case "B2B":
                return getDictionaryString("searchPlaceholderB2b");
            default:
                return getDictionaryString("searchPlaceholder");
        }
    }, [currentSite, getDictionaryString]);

    return (
        <ReactFocusLock disabled={!props.trapFocus || totalResults === 0 || false}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div className={S.wrapper} ref={wrapperRef} onKeyDown={onKeyDown}>
                <form role="search" className={S.form} onSubmit={onSubmit}>
                    <input
                        type="search"
                        name="term"
                        className={S.input}
                        placeholder={searchPlaceholderText}
                        onChange={({ target }) => setSearchTerm(target.value)}
                        onFocus={onFocus}
                        autoComplete="off"
                        ref={inputRef}
                    />

                    {deferredSearchTerm ? (
                        <button
                            type="button"
                            className={S.button}
                            onClick={reset}
                            aria-label={getDictionaryString("accessibilityButtonReset")}
                        >
                            <DA_Icon name={DA_IconNames.Close} className={S.clearIcon} />
                        </button>
                    ) : (
                        <button
                            type="button"
                            className={S.button}
                            onClick={() => inputRef.current?.focus()}
                            tabIndex={-1}
                            aria-label={getDictionaryString("accessibilityButtonSearch")}
                        >
                            <DA_Icon name={DA_IconNames.Zoom} className={S.searchIcon} />
                        </button>
                    )}
                </form>

                <div className={S.incrementalSearchResults}>
                    {viewState === "DONE" ? (
                        <div className={S.resultsContainer}>
                            <ul className={S.productList}>
                                {currentSite === "CMS" && (
                                    <ul className={S.productList}>
                                        {results?.cms?.map((page, index) => (
                                            <li key={`${index}`}>
                                                <_SearchPage
                                                    highlightedString={searchTerm}
                                                    onResultClicked={onResultClicked}
                                                    {...page}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {(currentSite === "B2B" || currentSite === "MYFARM") &&
                                    relewiseResults.map((product: RelewiseSearchProduct) => (
                                        <li key={product.productId}>
                                            <_SearchProduct
                                                highlightedString={searchTerm}
                                                onResultClicked={onResultClicked}
                                                {...product}
                                            />
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    ) : null}

                    {/** Loading */}
                    {viewState === "LOADING" ? (
                        <div className={S.statusContainer}>
                            <DA_Spinner />
                        </div>
                    ) : null}

                    {/** Empty */}
                    {viewState === "EMPTY" ? (
                        <div className={S.statusContainer}>{getDictionaryString("noResults")}</div>
                    ) : null}

                    {/** Error */}
                    {viewState === "ERROR" ? (
                        <div className={S.statusContainer}>
                            {getDictionaryString("search error")}
                        </div>
                    ) : null}
                </div>
            </div>
        </ReactFocusLock>
    );
};
